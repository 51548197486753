import type { FC } from 'react';
import errorImage from '@images/payment-error.svg';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '~/shared/components/LanguageSelector';

interface Props {
    label?: string;
    errorCode: number;
}

const ErrorInfo: FC<Props> = (props) => {
    const { t } = useTranslation('common');

    let clientErrorText = '';
    
    switch (props.errorCode) {
    case 6:
        clientErrorText = 'errorInfo.tooFast';        
        break;
    default:
        clientErrorText = 'errorInfo.defaultLabel';
        break;
    }

    return (
        <div className='text-black flex justify-center items-center text-sm flex-col lg:text-xl text-center py-5 font-semibold space-y-5 h-screen lg:h-[70vh]'>
            <p>
                {  t(clientErrorText) }
            </p>
            <img
                className='mb-10'
                src={errorImage}
                width={138}
                alt="errorImage"
            />            
            <LanguageSelector />            
        </div>
    );
};

export default ErrorInfo;